import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus, InteractionType, RedirectRequest } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { LoggedInUserModulesService } from './_services/login.service';
import { AppConfig } from './_utils/app-config/app-config.service';
import { EnvService } from './env.service';
import { AppConstants } from './_utils/app-constants';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  title = "Device Compliance Dashboard";
  isAuthenticated: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  @ViewChild('copiedAlert', {static: false}) copiedAlert: ElementRef;
  isEnvBannerOpen: boolean = true;
  isProdEnv: boolean = false;
  currentEnvironment : string = '';
  envTitle: string;

  constructor(private router: Router,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService, 
    private env: EnvService,
    private loginService: LoggedInUserModulesService,
    private msalBroadcastService: MsalBroadcastService) {
    //localStorage.setItem('token','eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyIsImtpZCI6Ii1LSTNROW5OUjdiUm9meG1lWm9YcWJIWkdldyJ9.eyJhdWQiOiI4ZjRhOTlhZC0xMmNlLTRkZmUtOWIwMS02YjU2NTczYTY4YzAiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC8zNmRhNDVmMS1kZDJjLTRkMWYtYWYxMy01YWJlNDZiOTk5MjEvIiwiaWF0IjoxNjc4OTU4ODY3LCJuYmYiOjE2Nzg5NTg4NjcsImV4cCI6MTY3ODk2Mjc2NywiYWlvIjoiQVdRQW0vOFRBQUFBcFMvTXFVQ1k5U2hrRlkxcjBCOTlwWHV4NW8vNEgzS1NMNGhBaVFaenJSK2EyNnh5NmFySDBKa0lKMjM1eHBUaGx6cVRzbEJMdk1PVThRUm9uWXpqY25Ybk41R0JxUWZNbWs1em5NazdaOE5Sd1ZtM1laZ3dWZUt2K1NhTTczcSsiLCJhbXIiOlsicHdkIiwicnNhIiwibWZhIl0sImZhbWlseV9uYW1lIjoiU2V0aHVyYW1hbiIsImdpdmVuX25hbWUiOiJIYXJpc2hrdW1hciIsImlwYWRkciI6IjE1LjIwNi4xMzUuMTkiLCJuYW1lIjoiU2V0aHVyYW1hbiwgSGFyaXNoa3VtYXIiLCJub25jZSI6ImNmNDY4ZWZjLTJmZmQtNGE2NC1iZGI3LTg5M2ZjYzc2NjliMCIsIm9pZCI6ImQ4ZTY5OTI5LTE0MjUtNDZhZS1iYjdjLThjMmE5OTZmNTkzMiIsIm9ucHJlbV9zaWQiOiJTLTEtNS0yMS0yMzg0NDcyNzYtMTA0MDg2MTkyMy0xODUwOTUyNzg4LTM2NDE4MDgiLCJyaCI6IjAuQVNZQThVWGFOaXpkSDAydkUxcS1Scm1aSWEyWlNvX09FdjVObXdGclZsYzZhTUFtQUE4LiIsInN1YiI6IlVCZ25FckhaTnNCY05iQVJqZHVYajBjaXQ5ZVBvV0pac3NSMjFXNHBDRVEiLCJ0aWQiOiIzNmRhNDVmMS1kZDJjLTRkMWYtYWYxMy01YWJlNDZiOTk5MjEiLCJ1bmlxdWVfbmFtZSI6ImhzZXRodXJhbWFuQGRlbG9pdHRlLmNvbSIsInVwbiI6ImhzZXRodXJhbWFuQGRlbG9pdHRlLmNvbSIsInV0aSI6IjVNbVluQzRVSlVHWG50U19ad1FlQVEiLCJ2ZXIiOiIxLjAifQ.Wedw06BmOhX3GmSGdlyZwLirL2S2QQVk6NDIJV4Te-bol0TijuAH8UMzCt3Pwjhv1z215Tx3NFMjvvCiIQGmwusocctoYuUNtuskSFDNRHNGNnO1JrGxw7Yiy8woxX1nJdK_SfwPl9_rdZ7ZnZk3iH54uW2Xt-fV1tAvdjx5WbJRcUOf5wz1A3WXgBbWYFy2Ly20IWwiE-x6a0osnI_2PT3he2plcR0xcnWP6_6nmtZQ6zUmTTAt2cEHmWJTABZDoGl0uG4BjJCfEqHRlwq3BYIJI4DG9u9uPOF0LnpHVKKM74W_RDYm8gzUXLFi72yoDCeYCgsBmCn0pyEAgIvJXA');
    //localStorage.setItem('apiurl', 'https://ddcdapi.deloitte.com/v1/api/');
  }

  ngAfterViewInit(): void {
    this.loginService.copyElementRef = this.copiedAlert;
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
  ngOnInit() {
    this.loginService.setSessionTimeout();
    this.initializeBanner();
    if (AppConfig.settings.debug) {
      //localStorage.setItem('loggedInUserName', 'amlkumar@deloitte.com');
      this.loginService.isAuthenticated$.next(true);
      this.isAuthenticated = true;
      return;
    }
    if(!this.isAuthenticated) {
      this.setAccountDetails();
    }
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      ).subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.instance.setActiveAccount(payload.account);
        this.setAccountDetails();
        if(result.interactionType === InteractionType.Redirect) {
          this.router.navigate(['']);
        }
    });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)
        , takeUntil(this._destroying$))
      .subscribe(async () => {
        if (!this.isAuthenticated) {
          await this.logIn();
        }
    });
      
    AppConstants.API_URL = this.env.apiurl;
    localStorage.setItem('apiurl', this.env.apiurl);
    localStorage.setItem('apiurlv2', this.env.apiurlv2);
    localStorage.setItem('env', this.env.environment);

    this.loadCookiesScript();
  }

  // get authenticated(): boolean {
  //   return this.authService.instance.getActiveAccount() ? true : false;
  // }

  setAccountDetails() {
    let activeAccount = this.authService.instance.getAllAccounts()[0];
    this.isAuthenticated = activeAccount ? true : false;
    if (activeAccount) {
      localStorage.setItem('loggedInUserName', activeAccount.username);
    }
    this.loginService.isAuthenticated$.next(this.isAuthenticated);
  }

  async logIn() {
    if (this.msalGuardConfig.authRequest) {
      await this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      await this.authService.loginRedirect();
    }
  };

  loadCookiesScript() {
    const cookieScripts = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    const cookieConsent = AppConfig.settings.cookieConsent;
    const head = document.getElementsByTagName('head')[0];
    let node = document.createElement('script');
    node.src = cookieScripts;
    node.type = 'text/javascript';
    node.async = false;
    node.charset = 'utf-8';
    node.setAttribute('data-domain-script', cookieConsent);
    node.setAttribute("nonce", "nonce-value");
    head.insertBefore(node, head.firstChild);
  }

  toggleEnvBanner() {
    this.isEnvBannerOpen = !this.isEnvBannerOpen;
  }

  initializeBanner() {
    const displayName = AppConfig.settings.env.displayName?.toLocaleLowerCase();
    this.isEnvBannerOpen = true;
    this.isProdEnv = false;
    if(displayName === 'dev') {
      this.envTitle = 'development';
      this.currentEnvironment = 'dev';
    } else if(displayName === 'qa') {
      this.envTitle = 'QA';
      this.currentEnvironment = 'qa';
    } else if(displayName === 'stage') {
      this.envTitle = 'UAT';
      this.currentEnvironment = 'stage';
    } else {
      this.isEnvBannerOpen = false;
    }
  }



}
